<template>
    <div id="app">
        <div class="body fixed mobile">
            <!-- class: fixed, mobile -->
            <!-- class: fixed, mobile -->
            <!-- Loading Spinner -->
            <!-- <div class="spinner"><div><div></div></div></div> -->
            <Spinner :spin="spin"/>
            <!-- Header -->
            <section class="header point_send">
                <a class="icon iconButton black" @click.prevent="goBack()">
                    <font-awesome-icon
                        icon="fa-solid fa-chevron-left"
                        size="lg"
                    />
                </a>
                <span class="fL">點數消費</span>
                <div class="icon iconButton"></div>
            </section>
            <!-- Main -->
            <section class="main point_send">
                <div class="record flexH width">
                    <div class="flexV width center">
                        <span class="fS gray">紅利桃子</span>
                        <div class="flexH">
                            <div class="img">
                                <img src="@/assets/icon/point.svg" />
                            </div>
                            <span class="fM bold">{{ displayBalance }}</span>
                        </div>
                    </div>
                    <div class="lineV"></div>
                    <div class="flexV width center">
                        <span class="fS gray"
                            >{{ displayPointExpireTime }}即將到期</span
                        >
                        <div class="flexH">
                            <div class="img">
                                <img src="@/assets/icon/point.svg" />
                            </div>
                            <span class="fM bold">{{
                                displayExpiredBalance
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="info flexV width center">
                    <span class="fM gray">您即將支付</span>
                    <div
                        class="total flexH width rounded middle padding margin background"
                    >
                        <div class="img">
                            <img src="@/assets/icon/point.svg" />
                        </div>
                        <span :class="['fXL', { error: checkPoints }]">{{
                            displayAmount()
                        }}</span>
                    </div>
                    <div class="hint flexH between">
                        <div class="flexH width">
                            <!-- 餘額不足提示 -->
                            <span class="fS error" v-if="checkPoints"
                                >餘額不足</span
                            >
                        </div>
                        <span class="fS gray">給</span>
                        <div class="flexH width right">
                            <div class="img">
                                <img src="@/assets/icon/point.svg" />
                            </div>
                            <span class="fS gray">1 = NT$1</span>
                        </div>
                    </div>
                    <span class="fL gray">{{ storeInfo.storeName }}</span>
                </div>
                <div class="number flexV width">
                    <div class="flexH width">
                        <a
                            class="flexH width middle margin background rounded"
                            v-for="value in [1, 2, 3]"
                            :key="'col1' + value"
                            @click="calculate(value)"
                        >
                            <span class="fXL">{{ value }}</span>
                        </a>
                    </div>
                    <div class="flexH width">
                        <a
                            class="flexH width middle margin background rounded"
                            v-for="value in [4, 5, 6]"
                            :key="'col2' + value"
                            @click="calculate(value)"
                        >
                            <span class="fXL">{{ value }}</span>
                        </a>
                    </div>
                    <div class="flexH width">
                        <a
                            class="flexH width middle margin background rounded"
                            v-for="value in [7, 8, 9]"
                            :key="'col3' + value"
                            @click="calculate(value)"
                        >
                            <span class="fXL">{{ value }}</span>
                        </a>
                    </div>
                    <div class="flexH width">
                        <a
                            class="flexH width middle margin"
                            @click="calculate('clear')"
                        >
                            <span class="fL gray">清除</span>
                        </a>
                        <a
                            class="flexH width middle margin background rounded"
                            @click="calculate(0)"
                        >
                            <span class="fXL">0</span>
                        </a>
                        <a
                            class="flexH width middle margin"
                            @click="calculate('del')"
                        >
                            <font-awesome-icon icon="fa-solid fa-delete-left" />
                        </a>
                    </div>
                </div>
                <a class="button rounded" :class="{ disabled: checkPoints }" @click.prevent="popConfirm()">
                    <span class="fM">確認送出</span>
                </a>
            </section>
            <!-- Popup (餘額不足) -->
            <section id="popNotice" class="popup">
                <div class="popBody">
                    <a class="close"
                        ><font-awesome-icon icon="fa-solid fa-times"
                    /></a>
                    <section class="popHeader">
                        <!-- <span class="fL">Title</span> -->
                    </section>
                    <section class="popMain">
                        <span class="fL">餘額不足</span>
                    </section>
                    <section class="popFooter">
                        <a class="button submit rounded"
                            ><span class="fM">確認</span></a
                        >
                    </section>
                </div>
            </section>
            <!-- Popup (Confirm) -->
            <section id="popConfirm" class="popup scan_point popConfirm">
                <div class="popBody">
                    <a class="close">
                       <font-awesome-icon icon="fa-solid fa-times" />
                    </a>
                    <section class="popMain">
                        <span class="fM gray">您即將支付</span>
                        <div class="total flexH width rounded middle padding margin background">
                            <div class="img"><img src="@/assets/icon/point.svg"></div>
                            <span class="fXL"> {{ displayAmount() }} </span>
                        </div>
                        <span class="fS gray">給</span>
                        <span class="fL gray">{{ storeInfo.storeName }}</span>
                    </section>
                    <section class="popFooter">
                        <a class="button rounded white cancel">
                            <span class="fM">取消</span>
                        </a>
                        <a class="button rounded submit">
                            <span class="fM">確認送出</span>
                        </a>
                    </section>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
import Spinner from '@/components/Spinner';

export default {
    name: "Consumption",
    components: {
        Spinner
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            points: [],
            consumptionData: {
                use: [],
                amount: 0
            },
            storeCode: this.$route.query.storeCode,
            storeInfo: {
                storeId: "",
                storeName: "",
                resourceEntityType: "",
                resourceEntityId: ""
            },
            spin: false,
            isProcessing: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                next("/error");
            }
        });
    },
    created: function () {
        this.getUserPoints()
            .then((res) => {
                this.$store.commit("updateUserPoints", res);
                this.points = JSON.parse(
                    JSON.stringify(this.userPoints)
                );
                console.log("this.points: ", this.points);
            })
            .catch((err) => {
                console.log("err: ", err);
            });
        this.getAppointedStoreInfo()
            .then((res) => {
                console.log("getAppointedStoreInfoRes: ", res);
                Object.assign(this.storeInfo, res);
            })
            .catch((err) => {
                console.log("err: ", err);
                this.$router.push("/error");
            });
    },
    mounted: function () {
        console.log("success!");
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters({
            userPoints: "getTyUserPoints"
        }),
        displayBalance() {
            return this.points && this.points.pointBalance
                ? this.points.pointBalance
                : 0;
        },
        displayPointExpireTime() {
            if (
                this.points &&
                this.points.pointPeriods &&
                this.points.pointPeriods.length > 0
            ) {
                const latestPeriod = this.points.pointPeriods.find(p => p.endTs >= moment().unix());
                return this.tsToDate(latestPeriod.endTs);
                // return this.tsToDate(this.points.pointPeriods[0].endTs);
            } else {
                return "";
            }
        },
        displayExpiredBalance() {
            if (
                this.points &&
                this.points.pointPeriods &&
                this.points.pointPeriods.length > 0
            ) {
                const latestPeriod = this.points.pointPeriods.find(p => p.endTs >= moment().unix());
                return latestPeriod.pointBalance;
            } else {
                return 0;
            }
            // return this.points &&
            //     this.points.pointPeriods &&
            //     this.points.pointPeriods.length > 0
            //     ? this.points.pointPeriods[0].pointBalance
            //     : 0;
        },
        checkPoints() {
            return this.points && this.points.pointBalance
                ? this.points.pointBalance < this.consumptionData.amount
                : false;
        },
        // sortedPeriods() {
        //     if (
        //         this.points &&
        //         this.points.pointPeriods &&
        //         this.points.pointPeriods.length > 0
        //     ) {
        //         let sorted = [...this.points.pointPeriods];
        //         sorted.sort(function(a, b) {
        //             return a.endTs - b.endTs;
        //         })
        //         return sorted;
        //     }
        //     return [];
        // }
    },
    methods: {
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD");
        },
        displayAmount() {
            let arr = JSON.parse(JSON.stringify(this.consumptionData.use));
            this.consumptionData.amount = arr
                .reverse()
                .reduce((accumulator, currentValue, currentIndex) => {
                    let base = currentValue;
                    let exponentPower = currentIndex;
                    accumulator += base * 10 ** exponentPower;
                    return accumulator;
                }, 0);
            return this.consumptionData.amount;
        },
        popNotice() {
            var popNotice = document.getElementById("popNotice");
            var close = popNotice.getElementsByClassName("close")[0];
            var submit = popNotice.getElementsByClassName("submit")[0];
            close.onclick = function () {
                popNotice.style.display = "none";
            };
            submit.onclick = function () {
                popNotice.style.display = "none";
            };
            window.onclick = function (event) {
                if (event.target == popNotice) {
                    popNotice.style.display = "none";
                }
            };
            popNotice.style.display = "flex";
        },
        popConfirm() {
            var popConfirm = document.getElementById("popConfirm");
            var close = popConfirm.getElementsByClassName("close")[0];
            var submit = popConfirm.getElementsByClassName("submit")[0];
            var cancel = popConfirm.getElementsByClassName("cancel")[0];
            let self = this;
            close.onclick = function () {
                popConfirm.style.display = "none";
            };
            cancel.onclick = function () {
                popConfirm.style.display = "none";
            };
            submit.onclick = function () {
                popConfirm.style.display = "none";
                self.confirm();
            };
            window.onclick = function (event) {
                if (event.target == popConfirm) {
                    popConfirm.style.display = "none";
                }
            };
            popConfirm.style.display = "flex";
        },
        calculate(cmd) {
            if (cmd == "clear") {
                this.consumptionData.use = [];
            } else if (cmd == "del") {
                this.consumptionData.use.pop();
            } else if (this.consumptionData.use.length == 0 && cmd == 0) {
                //
            } else {
                this.consumptionData.use.push(cmd);
            }
        },
        checkBalance() {
            if (this.points.pointBalance >= this.consumptionData.amount) {
                return true;
            } else {
                return false;
            }
        },
        callUsePoints() {
            let that = this;
            var data = JSON.stringify({
                receiverEntityType: "Store",
                receiverEntityId: this.storeInfo.resourceEntityId,
                points: this.consumptionData.amount,
                note: `${this.storeInfo.storeName}`
            });

            var config = {
                method: "post",
                url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/orders`,
                headers: {
                    "Content-Type": "application/json"
                },
                data: data
            };
            this.spin = true;
            this.$http(config)
                .then((response) => {
                    this.spin = false;
                    console.log(JSON.stringify(response.data));
                    //  使用replace，避免按手機回上一頁時，跳到付款頁
                    that.$router.replace({
                        path: "/consumption-result",
                        query: response.data
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.spin = false;
                });
        },
        confirm() {
            // spin 在confirm時擋，避免重複付點
            if (this.spin) return;
            this.spin = true;
            if (this.checkBalance()) {
                this.callUsePoints();
            } else {
                this.popNotice();
                this.spin = false;
            }
        },
        getUserPoints() {
            var config = {
                method: "get",
                url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
                headers: {}
            };

            return this.$http(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getAppointedStoreInfo() {
            console.log(this.storeCode);
            let that = this;
            var config = {
                method: "get",
                url: `${this.apiHost}/stores/v1/merchants/${this.merchantId}/store-codes/${this.storeCode}`,
                headers: {}
            };

            return this.$http(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                    that.$router.push("/home");
                });
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        }
    }
};
</script>

